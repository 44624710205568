const NOT_USED_SPEND = 'NUS: '

export function stringCroppedView(string, length = 15) {
  if (!string) return
  if (typeof string === 'string' && string.includes(NOT_USED_SPEND)){
    return string.replace(NOT_USED_SPEND, '')
  }
  return string.length > length
    ? string.substring(0, length) + '...'
    : string
}