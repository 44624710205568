import moment from "moment/moment";
import {DATE_FORM_FORMAT} from "@/constants/date";

export const defaultFilters = {
  filter: {
    dateFrom: moment().format(DATE_FORM_FORMAT),
    dateTo: moment().format(DATE_FORM_FORMAT),
    countries: null,
    advertisers: null,
    partners: null,
    partnersGroups: null,
    managers: null,
    paymentsModel: null,
    siteId: ''
  }
}