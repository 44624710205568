import moment from 'moment'
import {get, isArray, isBoolean, isEmpty, isNumber, isObject, isString, omit, omitBy, orderBy, size} from 'lodash'
import {DATE_TIME_FORMAT} from '@/constants/date'

export function omitEmptyFilters(filters) {
  return omitBy(
    filters,
    value =>
      (!isBoolean(value) && !isNumber(value) && !value) || (isArray(value) && size(value) === 0),
  )
}

export function joinBySymbol(arr = [], symbol = '|', path = '') {
  return Array.isArray(arr) ? arr.map(item => path ? item[path].id : item.id || item).join(symbol) : arr
}

export function omitEmptyDeepFilters(obj = {}) {
  return Object.entries(obj).reduce((cur, [key, value]) => {
    if (isObject(value)) {
      cur[key] = omitEmptyDeepFilters(value)

      if (isEmpty(cur[key])) {
        delete cur[key]
      }
    } else {
      if (['operator', 'value'].includes(key) && obj.operator && !obj.value) {
        // ignoring operator and value keys if value is empty
      } else {
        if (
          !(!isBoolean(value) && !isNumber(value) && !value) ||
          (isArray(value) && size(value) === 0)
        ) {
          cur[key] = value
        }
      }
    }

    return cur
  }, {})
}

export function prepareFilters(filters) {
  return prepareSortValues(removeClientOptions(filters))
}

function removeClientOptions(filters) {
  return omit(filters, ['clientOptions'])
}

export function prepareSortValues(filters) {
  const restFilters = omit(filters, ['sortBy', 'sortDesc'])
  let sortBy = filters.sortBy || null
  let sortDesc = filters.sortDesc

  if (!sortBy) {
    return filters
  }

  sortBy = isArray(sortBy) ? sortBy : [sortBy]
  sortDesc = isArray(sortDesc) ? sortDesc : [sortDesc]

  const orderObject = sortBy.reduce((result, sortName, index) => {
    const direction = {
      filedName: sortDesc[index] ? 'DESC' : 'ASC',
    }
    const namesArray = sortName.split('.').reverse()
    const hasNestedValues = namesArray.length > 1
    let sortValue = {}

    if (hasNestedValues) {
      sortValue = namesArray.reduce((prev, current) => ({[current]: {...prev}}), direction)
    } else {
      sortValue = {
        [sortName]: direction,
      }
    }

    return {...result, ...sortValue}
  }, {})

  return {
    ...restFilters,
    order: orderObject,
  }
}
export function byField(field, desc) {
  if (desc) {
    return (a, b) => (a[field] < b[field] ? 1 : -1)
  } else {
    return (a, b) => (a[field] > b[field] ? 1 : -1)
  }
}

export function sortByFilters(data, filters) {
  const sortDescArr = filters.sortDesc

  let activeIndex = sortDescArr.lastIndexOf(true)

  if (activeIndex === -1) {
    activeIndex = 0
  }

  const sortField = filters.sortBy[activeIndex]

  const sortDesc = filters.sortDesc[activeIndex]

  return data.sort(byField(sortField, sortDesc))
}

export function paginationFilters(data, pagination) {
  if (!data) {
    return []
  }
  const page = pagination.page
  const perPage = pagination.limit
  const from = page * perPage - perPage
  const to = page * perPage

  return data.slice(from, to)
}

const monthOrder = [
  'january', 'february', 'march',
  'april', 'may', 'june', 'july',
  'august', 'september', 'october',
  'november', 'december'
];
const getMonthIndex = month => monthOrder.indexOf(month.toLowerCase())
const getWeekStart = (weekRange) => moment(weekRange.split(' - ')[0], 'DD.MM.YYYY');

function sortGroupList(group, sortKey) {
  const value = get(group, sortKey)
  if (sortKey.includes('month')) {
    const year = get(group, 'groups.year')
    group.groups.monthIndex = value && isString(value) ? getMonthIndex(value) : -1;
    return year;
  } else if (sortKey.includes('week')) {
    const date = value && isString(value) ? getWeekStart(value) : moment.invalid();
    return date.isValid() ? date : moment.invalid();
  } else {
    return value && isString(value) ? value.toLowerCase() : value;
  }
}

export function getSortedItems(groupedItems, {sortBy, sortDesc}, page = 'default') {
  const sortDirection = isArray(sortDesc) ? sortDesc[0] : sortDesc
  let prepSortKey = (isArray(sortBy) ? sortBy[0] : sortBy) || 'title'
  const sortKey = prepSortKey === 'groups.month'
    ? 'groups.month_title' : prepSortKey === 'budget' && page === 'budgetReport'
    ? 'budget_value' : prepSortKey
  const orders = [sortDirection ? 'desc' : 'asc']
  const sortFunc = group => sortGroupList(group, sortKey)

  if (page === 'budgetReport') {
    groupedItems.forEach(item => {
      item.groups = orderBy(item.groups, sortFunc, orders)
      if (sortKey === 'actual_budget') {
        item.groups.forEach(group => {
          group.partnersPrep = orderBy(group.partnersPrep, sortFunc, orders)
        })
      }
    })
  }
  if(sortKey === 'groups.month_title') {
    orderBy(groupedItems, sortFunc)
    return orderBy(groupedItems, [(i) => i.groups.year, (i) => i.groups.monthIndex], [...orders, ...orders])
  }
  else
    return orderBy(groupedItems, sortFunc, orders)
}

export function getPaginatedList(items, pagination, {sortBy, sortDesc}, page = 'default') {
  const sortedList = getSortedItems(items, {sortBy, sortDesc}, page)
  return paginationFilters(sortedList, pagination)
}

export function mockPagination(data, filters) {
  return {
    currentPage: filters.pagination.page,
    limit: filters.pagination.limit,
    maxPageNumber: Math.ceil(data.length / filters.pagination.limit),
    total: data.length,
  }
}

export function prepareSelectAllFilters(selectedItems, key = 'id') {
  return {
    filter: {
      ...(selectedItems.length
        ? {
          [key]: {
            operator: 'nin',
            value: selectedItems.map(item => item[key]).join('|'),
          },
        }
        : {
          [key]: {
            operator: 'gt',
            value: 0,
          },
        }),
      active: 1,
    },
  }
}

export function hasAppliedFilters(filters) {
  const appliedFilters = omitEmptyDeepFilters(filters.filter)
  const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
  const appliedCustomFilters = omitEmptyDeepFilters(filters.custom_filter)

  return (
    Object.keys(appliedFilters).length ||
    Object.keys(appliedCfFilters).length ||
    Object.keys(appliedCustomFilters).length
  )
}

export function prepareRangeDateFilter(date, format) {
  if (!Array.isArray(date)) return date

  const [start, end] = date

  const preparedDate = [
    start,
    moment(end)
      .endOf('day')
      .format(format || DATE_TIME_FORMAT),
  ]

  return {
    value: preparedDate.join('|'),
    operator: 'bwn',
  }
}

export function prepareRangeDateMonthFilter(date, format) {
  const year = moment(date).year()
  const month = moment(date).month()

  const dateFrom = moment([year, month, 1]).format(format)
  const dateTo = moment(dateFrom).endOf('month').format(format)

  const preparedDate = [dateFrom, dateTo]

  return {
    value: preparedDate.join('|'),
    operator: 'bwn',
  }
}
