import SuperRepository from '../superRepository'

export default new (class OfferPartnersRepository extends SuperRepository {
  baseUri() {
    return 'offer_partner'
  }
  updateCostHistory = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}/update_cost_history`, params)
  updateBlDepositHistory = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}/update_bl_deposit_history`, params)
  updateBlPeriodHistory = async ({ id, ...params }) =>
    this.httpClient().put(`${this.baseUri()}/${id}/update_bl_period_history`, params)
})()
