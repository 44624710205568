import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/Postbacks/helpers'
import postbacksRepository from '@/repository/generatedRepository/postbacksRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import moment from "moment";
import {DATE_FORMAT_SHORT} from "@/constants/date";
import i18n from "@/plugins/vue-i18n";
const {saveAs} = require('file-saver')

const state = {
  list: [],
  filters: StorageService.get(storageKeys.POSTBACKS_FILTERS) || { ...defaultFilters() },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.POSTBACKS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      signal
    })

    const { data } = await postbacksRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadPartnerData(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await postbacksRepository.list(preparedFilters)
    context.commit('setList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const response = await postbacksRepository.exportPostbacksReport(
      {...preparedFilters}, {responseType: 'blob'},)
    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const dates = context.getters.filters.filter.createdAt.value.split('|')
    const filename = `${i18n.tc('postbacks.listTitle')} ${
      moment(dates[0]).format(DATE_FORMAT_SHORT)}-${moment(dates[1]).format(DATE_FORMAT_SHORT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async searchPostbacks(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await postbacksRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await postbacksRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return postbacksRepository.post(payload)
  },
  resendPostbacks(context, payload) {
    return postbacksRepository.resendPostbacks(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return postbacksRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return postbacksRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.POSTBACKS_FILTERS)
  },
  resetSearchFilter({commit}){
    commit('clearSearch')
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.POSTBACKS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  clearSearch(state) {
    state.tableOptions.search = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
