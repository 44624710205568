export const defaultFilters = () => ({
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    partner: {
        value: '',
        operator: 'in'
    },
    offer: {
      value: '',
      operator: 'in'
    },
    stream: {
      value: '',
      operator: 'in'
    },
    country: {
      value: '',
      operator: 'in'
    }
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
})
