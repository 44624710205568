export const defaultFilters = () => ({
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: '',
  },
  sortBy: ['date'],
  sortDesc: [false],
})
