import SuperRepository from '../superRepository'

export default new (class PartnerReportRepository extends SuperRepository {
  baseUri() {
    return 'partner_report'
  }
  newDeposits = async data =>
    this.httpClient().post(`${this.baseUri()}/new_deposit`, data)
  streamReport = async data =>
    this.httpClient().post(`${this.baseUri()}/stream_report`, data)
})()
