export function prepareGeneralReportData(value){
  if(!value && value !== 0) return ''
  return Number(value) !== 0 ? Number(value).toFixed(2) : value
}

export function canFitInLocalStorage(data, limitMB = 1.5) {
  try {
    const jsonData = JSON.stringify(data);
    const jsonDataSize = jsonData.length * 2; // data size (bytes)
    return jsonDataSize < limitMB * 1024 * 1024 // limit in MB
  } catch (e) {
    return false;
  }
}

export function defineGroupId(group) {
  return group === 'traffic_source' ? 'traffic' : group === 'actual_country' ? 'geo_country' :
    group === 'click_id' ? 'player_id' : group
}