import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/Advertisers/helpers'
import advertisersRepository from '@/repository/generatedRepository/advertisersRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import advertiserCountryRepository from "@/repository/generatedRepository/advertiserCountryRepository";
import AdvertiserLandingRepository from "@/repository/generatedRepository/advertiserLandingRepository";
import AdvertiserLandingOrderRepository from "@/repository/generatedRepository/advertiserLandingOrderRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.ADVERTISERS_FILTERS) || { ...defaultFilters() },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ADVERTISERS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      signal
    })

    const { data } = await advertisersRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchAdvertisers(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await advertisersRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await advertisersRepository.get(id)
    return data
  },
  async loadAdvertiserCountry(context, id) {
    const { data } = await advertiserCountryRepository.list(id)
    return { countries: data.items, pagination: data.pagination }
  },
  async loadAdvertiserLanding(context, id) {
    const { data } = await AdvertiserLandingRepository.list(id)
    return { landings: data.items, pagination: data.pagination }
  },
  async loadAdvertiserLandingList(context, filters) {
    const { data } = await AdvertiserLandingRepository.list(filters)
    return { landings: data.items, pagination: data.pagination }
  },
  async loadAdvertiserLandingOrder(context, filters) {
    const { data } = await AdvertiserLandingOrderRepository.list(filters)
    return { landings: data.items, pagination: data.pagination }
  },
  createItem(context, payload) {
    return advertisersRepository.post(payload)
  },
  createAdvertiserCountry(context, payload) {
    return advertiserCountryRepository.post(payload)
  },
  createAdvertiserLanding(context, payload) {
    return AdvertiserLandingRepository.post(payload)
  },
  createAdvertiserLandingOrder(context, payload) {
    return AdvertiserLandingOrderRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return advertisersRepository.put(id, data)
  },
  updateAdvertiserCountry(context, payload) {
    const { id, ...data } = payload
    return advertiserCountryRepository.put(id, data)
  },
  updateAdvertiserLanding(context, payload) {
    const { id, ...data } = payload
    return AdvertiserLandingRepository.put(id, data)
  },
  updateAdvertiserLandingOrder(context, payload) {
    const { id, ...data } = payload
    return AdvertiserLandingOrderRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return advertisersRepository.delete(payload)
  },
  deleteAdvertiserCountry(context, payload) {
    return advertiserCountryRepository.delete(payload)
  },
  deleteAdvertiserLanding(context, payload) {
    return AdvertiserLandingRepository.delete(payload)
  },
  deleteAdvertiserLandingOrder(context, payload) {
    return AdvertiserLandingOrderRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ADVERTISERS_FILTERS)
  },
  resetSearchFilter({commit}){
    commit('clearSearch')
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ADVERTISERS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  clearSearch(state) {
    state.tableOptions.search = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
