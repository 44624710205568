import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/Offer/helpers'
import offerRepository from '@/repository/generatedRepository/offerRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import offerPartnerRepository from '@/repository/generatedRepository/offerPartnersRepository'

const state = {
  list: [],
  filters: StorageService.get(storageKeys.OFFER_FILTERS) || { ...defaultFilters() },
  tableOptions: TableOptions.defaultTableOptions(),
  unapprovedOffers: 0,
  partnerGroupOfferList: [],
  partnerGroupOfferTableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  list: state => state.list,
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  unapprovedOffers: state => state.unapprovedOffers,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  partnerGroupOfferList: state => state.partnerGroupOfferList,
  partnerGroupOfferTableOptions: state => state.partnerGroupOfferTableOptions,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OFFER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      signal
    })

    const { data } = await offerRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadPartnerGroupOfferData(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await offerRepository.list(preparedFilters)

    context.commit('setPartnerGroupOfferList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchOffer(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await offerRepository.list(preparedFilters)
    context.commit('setUnapprovedOffers', { data })

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await offerRepository.get(id)
    return data
  },
  async loadOfferPartner(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await offerPartnerRepository.list(preparedFilters)
    return { products: data.items, pagination: data.pagination }
  },
  createItem(context, payload) {
    return offerRepository.post(payload)
  },
  createOfferPartner(context, payload) {
    return offerPartnerRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return offerRepository.put(id, data)
  },
  changeOfferState(context, payload) {
    const { id, ...data } = payload
    return offerRepository.activate(id, data)
  },
  updateOfferPartner(context, payload) {
    const { id, ...data } = payload
    return offerPartnerRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return offerRepository.delete(payload)
  },
  deleteOfferPartner(context, payload) {
    return offerPartnerRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OFFER_FILTERS)
  },
  resetSearchFilter({commit}){
    commit('clearSearch')
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OFFER_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.unapprovedOffers = data.count_data.unapproved[0]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setUnapprovedOffers(state, { data }) {
    state.unapprovedOffers = data.count_data.unapproved[0]
  },
  setPartnerGroupOfferList(state, { data, filters }) {
    state.partnerGroupOfferList = [...data.items]
    state.partnerGroupOfferTableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  clearSearch(state) {
    state.tableOptions.search = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
