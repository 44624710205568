export const defaultFilters = () => ({
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
      active: 1,
      leaders: null,
      partners: null,
      offers: null,
      partnersGroup: null,
    },
    sortBy: ['active', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: false,
    },
})
