import PlayerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";


import StorageService from "@/services/storage.service";
import storageKeys from "@/constants/storageKeys";

const state = {
  reportNotifications: {
    update_base_line: null,
    update_report_data: null,
    update_spend: null,
  },
  isCacheUpdated: true,
}

const getters = {
  reportNotifications: state => state.reportNotifications,
  isCacheUpdated: state => state.isCacheUpdated,
}

const actions = {
  async loadNotification(context) {
    const { data } = await PlayerReportsRepository.playerReportNotification()
    // const data = {
      // update_base_line: null,
      // update_report_data: null,
      // update_spend: null,
      // update_spend: '2024-05-04',
    // }
    const isCacheUpdated = Object.keys(data).every(v => !data[v])
    context.commit('setNotification', {data, isCacheUpdated})
    return { reportNotifications: data, isCacheUpdated }
  },
  updateCurrentResult(context, currentResult){
    const lsData = StorageService.get(storageKeys.PLAYER_REPORT_NOTIFICATION)
    const lsDataPrepared = lsData ? {...lsData} : {}
    let result = {}
    result = {
      ...lsDataPrepared,
      ...currentResult
    }
    StorageService.set(storageKeys.PLAYER_REPORT_NOTIFICATION, result)
  },
  updateLocalStorage(context, {reportName, ...updatedFields}){
    const lsData = StorageService.get(storageKeys.PLAYER_REPORT_NOTIFICATION)
    const lsDataPrepared = lsData ? {...lsData} : {}

    let result = {}
    result = {
      ...lsDataPrepared,
      [reportName]: {
        ...lsDataPrepared[reportName],
        ...updatedFields,
      }
    }
    StorageService.set(storageKeys.PLAYER_REPORT_NOTIFICATION, result)
  },
}

const mutations = {
  setNotification(state, {data, isCacheUpdated}) {
    state.reportNotifications = {...state.reportNotifications, ...data}
    state.isCacheUpdated = isCacheUpdated
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
