import { get } from 'lodash'
import i18n from '@/plugins/vue-i18n'
import { joinBySymbol } from '@/helpers/filters'

export function renderValueByKey(value, key) {
  return renderValue(get(value, key))
}

export function renderArrayOfObjectsByKey(value, key){
  if(!value.length) return ''
  return value.reduce((newArr, item) => {
    if(Array.isArray(key)){
      let newVal = []
      key.forEach(el => {
        if(el.includes('.')){
          let newItem = item
          el.split('.').map(el => newItem = newItem[el])
          if(newItem === null) return
          newVal.push(newItem)
        }
        else if (item[el]) {
          newVal.push(item[el])
        }
      })
      newArr.push(newVal.join(' '))
      return newArr
    }
    if(key.includes('.')){
      let newItem = item
      key.split('.').map(el => newItem = newItem[el])
      newArr.push(newItem)
    }
    else if (item[key]) {
      newArr.push(item[key])
    }
    return newArr
  }, [])
  .join(', ')
}
export function renderValue(value) {
  return value || '-'
}
export function renderValueYesNo(value) {
  return value ? i18n.tc('base.yes') : i18n.tc('base.no')
}
export function renderActive(value) {
  return value ? i18n.tc('base.listStatusActive') : i18n.tc('base.listStatusInactive')
}
export function renderValueJoinBySymbol(arr, symbol = ', ') {
  return joinBySymbol(arr, symbol)
}

export function numberWithSpaces(value, returnValue = '') {
  if (value === 'empty') return ''
  if (!value && value !== 0) return returnValue;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
