export default {
  // Data storage constants
  GENERAL_REPORT_DATA: 'general_report_data',
  GENERAL_REPORT_OLD_DATA: 'general_report_old_data',
  COHORT_ANALYSIS_DATA: 'cohort_analysis_data',
  BUDGET_REPORT_DATA: 'budget_report_data',
  DASHBOARD_CHART_DATA: 'dashboard_chart_data',
  DASHBOARD_TOP_PARTNERS_DATA: 'dashboard_top_partners_data',
  DASHBOARD_TOP_COUNTRIES_DATA: 'dashboard_top_countries_data',

  // Generated storage keys under this comment. DO NOT REMOVE ANY COMMENTS below
  // __auto_generated_storage_keys__
  NEW_STREAMS_FILTERS: 'filters_new_streams',
  DASHBOARD_FILTERS: 'filters_dashboard',
  DASHBOARD_EFFICIENCY_CHART: 'filters_dashboard_efficiency_chart',
  COSTS_REPORT_FILTERS: 'filters_costs_report',
  OFFER_PARTNERS_FILTERS: 'filters_offer_partners',
  SPENDS_PARSING_LOG_FILTERS: 'filters_spends_parsing_log',
  PARTNERS_SPENDS_TABLE_FILTERS: 'filters_partners_spends_table',
  TRAFFIC_SOURCES_FILTERS: 'filters_traffic_sources',
  LANDINGS_FILTERS: 'filters_landings',
  BUDGET_REPORT_FILTERS: 'filters_budget_report',
  PARTNER_REPORT_FILTERS: 'filters_partner_report',
  ROLES_FILTERS: 'filters_roles',
  PARTNER_GROUPS_FILTERS: 'filters_partner_groups',
  PARTNER_TAGS_FILTERS: 'filters_partner_tags',
  PLAYER_REPORT_NOTIFICATION: 'report_notifications',
  OFFER_PARTNER_TAGS_FILTERS: 'filters_offer_partner_tags',
  PARTNER_INFO_FILTERS: 'filters_partner_info',
  GENERAL_REPORT_OLD_FILTERS: 'filters_general_report_old',
  GENERAL_REPORT_FILTERS: 'filters_general_report',
  COHORT_ANALYSIS_FILTERS: 'filters_cohort_analysis',
  CURRENCIES_FILTERS: 'filters_currencies',
  PLAYERS_REPORTS_FILTERS: 'filters_players_reports',
  PLAYERS_REPORTS_API_FILTERS: 'filters_players_reports_api',
  PLAYER_REPORT_FILTERS: 'filters_player_report',
  PLAYERS_FILTERS: 'filters_players',
  PLAYER_FILTERS: 'filters_player',
  POSTBACKS_FILTERS: 'filters_postbacks',
  EVENTS_FILTERS: 'filters_events',
  PARTNERS_SPENDS_FILTERS: 'filters_partners_spends',
  USERS_FILTERS: 'filters_users',
  CLICKS_FILTERS: 'filters_clicks',
  OFFER_FILTERS: 'filters_offer',
  COUNTRY_FILTERS: 'filters_country',
  PRODUCTS_FILTERS: 'filters_products',
  PAYMENT_MODELS_FILTERS: 'filters_payment_models',
  ADVERTISERS_FILTERS: 'filters_advertisers',
  PARTNERS_FILTERS: 'filters_partners',
}
