export const defaultFilters = () => ({
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    state: '',
    offerPartners: {
      value: '',
      operator: 'in'
    },
    partnerGroups: '',
    partnersGroup: ''
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
})
