import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constants/date";

export const defaultFilters = () => ({
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        dateFrom: moment().format(DATE_FORM_FORMAT),
        dateTo: moment().format(DATE_FORM_FORMAT),
        advertisers: null,
        partnerGroups: null,
        paymentModels: null,
        geoCountry: null,
        siteId: ''
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: true,
    },
})