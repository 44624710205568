import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/Clicks/helpers'
import clicksRepository from '@/repository/generatedRepository/clicksRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import {cloneDeep} from "lodash";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.CLICKS_FILTERS) || { ...defaultFilters() },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {signal, ...filters} = payload
    const filtCopy = cloneDeep(filters)
    delete filtCopy.sortBy
    delete filtCopy.sortDesc
    context.commit('setFilters', filtCopy)
    StorageService.set(storageKeys.CLICKS_FILTERS, filters)
    const appliedCfFilters = omitEmptyDeepFilters(filtCopy.cfFilter)
    const appliedCustomFilters = omitEmptyDeepFilters(filtCopy.custom_filter)
    Object.keys(appliedCustomFilters).forEach(item => {
      if (!(appliedCustomFilters[item] instanceof Object)) return
      appliedCustomFilters[item] = Object.values(appliedCustomFilters[item])
    })

    const preparedFilters = prepareFilters({
      ...filtCopy,
      cfFilter: appliedCfFilters,
      custom_filter: appliedCustomFilters,
      signal
    })

    const { data } = await clicksRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await clicksRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return clicksRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return clicksRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return clicksRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CLICKS_FILTERS)
  },
  resetSearchFilter({commit}){
    commit('clearSearch')
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CLICKS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  clearSearch(state) {
    state.tableOptions.search = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
