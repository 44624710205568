export const defaultFilters = () => ({
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        createdAt: null,
        partnerGroup: null,
        partner: null,
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
})
