import StorageService from '@/services/storage.service'
import storageKeys from '@/constants/storageKeys'
import { defaultFilters } from '@/models/Player/helpers'
import PlayerReportsRepository from "@/repository/generatedRepository/playerReportsRepository";
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  list: [],
  filters: StorageService.get(storageKeys.PLAYER_FILTERS) || { ...defaultFilters() },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
}

const actions = {
  async loadPlayerReportData(context, payload) {
    const {id, filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PLAYER_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        player: {
          id: id
        },
        report: {
          value: 'isnt_null',
          operator: 'isnt_null'
        }
      },
      cfFilter: appliedCfFilters,
    })
    const { data } = await PlayerReportsRepository.list(preparedFilters)
    data.items.forEach(el => el.id = el.report.id)
    context.commit('setList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  filtersStorageReset(context) {
    StorageService.remove(storageKeys.PLAYER_FILTERS)
    context.commit('setFilters', {...defaultFilters()})
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
