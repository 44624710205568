export const defaultFilters = () => ({
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
      advertisers: '',
      partners: '',
      countries: '',
      paymentModels: '',
      products: '',
      partnersGroups: '',
      state: '',
      active: 1,
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
})
